import { api } from './api';
import type { RegisterPayload } from '@/types/register';

export class Register {
  static BASE_PATH = '';

  static async register(payload: RegisterPayload, headers?: Record<string, any>) {
    const response = await api.post(`${Register.BASE_PATH}register`, payload, headers);
    return response.data;
  }
}

export default Register;
