import { storeToRefs } from 'pinia';
import { useSiteStore } from "@/stores/site";
import { PageType } from "@/types";
import Register from '@/services/register';
import type { RegisterPayload } from '@/types/register';
import { ErrorCode } from "@/types/errors";
import { Facebook } from '@/utils/integrations';
import { handleUserAlreadySubscribedError } from '@/utils/handleUserAlreadySubscribed';
import { isSessionExpired } from '@/utils/session';

export function useRegisterCommon() {
  const domain = globalThis?.location?.origin;
  Register.BASE_PATH = `${domain}/api/`;

  const { isPreview, siteData, nextPage, countryCode } = storeToRefs(useSiteStore());

  const pageData = ref({
    registerType: siteData.value?.registerType,
    countryCode: siteData.value?.countryCode,
    phoneNumberAllowedCountries: siteData.value?.phoneNumberAllowedCountries
  });

  function checkCurrentSession() {
    if (isSessionExpired()) {
      globalThis?.location?.reload();
      return true;
    }

    return false;
  }

  async function registerUser(form: RegisterPayload): Promise<boolean | Record<string, any>> {
    return new Promise((resolve, reject) => {
      if (isPreview.value) {
        goToPageWithPreviewCountry(nextPage.value, countryCode.value);
        return resolve(true);
      }

      checkCurrentSession();

      if (pageData.value.registerType === 'phoneNumber') {
        form.subscriberId = (form.subscriberId && form.subscriberId.charAt(0) !== '+')
          ? `+${form.subscriberId}`
          : form.subscriberId;
      }

      Register.register(form, {
        hideErrorToastFor: [ErrorCode.USER_ALREADY_SUBSCRIBED_ERROR]
      }).then((response) => {
        const { meta } = response;

        if (meta.httpStatus === 200) {
          if (!siteData.value?.registerOnPayment) {
            Facebook.track('CompleteRegistration');
            setVisitedPages(PageType.REGISTER);
            goToPage(nextPage.value, { excludeQueryParams: ['provider', 'code'] });
          }
          resolve(response);
        }

        if (meta.errorCode === ErrorCode.USER_ALREADY_SUBSCRIBED_ERROR) {
          const route = useRoute();
          const { slug } = getBaseUrl(route?.fullPath);
          handleUserAlreadySubscribedError(form?.subscriberId, slug);
          resolve(response);
        }

        if (meta.errorCode) reject(response);
      }).catch((res) => reject(res));
    });
  }

  return {
    pageData,
    checkCurrentSession,
    registerUser
  }
}
