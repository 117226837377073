import { api } from './api';

export class Social {
  static BASE_PATH = 'social';

  static async login(payload: { provider: string }, headers?: Record<string, any>) {
    const route = headers ? '-login' : '/login'; // For BE api route is 'social-login', for our server route it is 'social/login'
    const response = await api.post(`${Social.BASE_PATH}${route}`, payload, headers);
    return response.data;
  }

  static async callback(payload: { provider: string; code: string }, headers?: Record<string, any>) {
    const route = headers ? '-callback' : '/callback'; // For BE api route is 'social-callback', for our server route it is 'social/callback'
    const response = await api.post(`${Social.BASE_PATH}${route}`, payload, headers);
    return response.data;
  }
}

export default Social;
