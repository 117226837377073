export default function getParamsFromQueryString(url: string) {
  const queryString = ((url.split("?")[1] || "").split('#')[0] || '').trim();
  if (!queryString) return {}
  const params = queryString.split("&").reduce((acc: Record<string, string>, item: string) => {
    const [key, value]: string[] = item.split("=")
    acc[key] = value;
    return acc;
  }, {})

  return params
}
