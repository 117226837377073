import { useSiteStore } from '@/stores/site';
import { PageType } from '@/types';
import { getPageWithPreviewCountryUrl } from '@/utils/getBaseUrl';

interface UseGeneratedLinksOptions {
  selector?: string;
}

export function useGeneratedLinks(options?: UseGeneratedLinksOptions) {
  const { selector = 'a.tw-element-button' } = options || {};

  const siteStore = useSiteStore();
  const { availablePages, nextPage, countryCode, isPreview } = storeToRefs(siteStore);
  const allPageTypes = Object.values(PageType);

  function generateLinks() {
    const elements = document.querySelectorAll(selector);
    elements.forEach((elementItem) => {
      const countryParam = isPreview.value ? countryCode.value : undefined;
      if (hasLink(elementItem)) {
        const url = elementItem.getAttribute('href') || '';
        const page = ((url && url.split('/').filter(Boolean)[0]) || '') as PageType;
        if (allPageTypes.includes(page)) {
          if (availablePages.value.includes(page)) {
            // If there is a page link and the page exists, go to that page
            elementItem.setAttribute('href', getPageWithPreviewCountryUrl(page, countryParam));
            return;
          }
          // If there is a page link and the page does not exist, clear the link
          elementItem.setAttribute('href', `javascript:;`);
          return;
        }
        // If url is not page link, open it in a new tab
        elementItem.setAttribute('target', '_blank');
        return;
      }
      // Go to next page if there is no link
      elementItem.setAttribute('href', getPageWithPreviewCountryUrl(nextPage.value, countryParam));
    });
  }

  function hasLink(element: Element) {
    const hrefValue = element.getAttribute('href');
    return !!hrefValue && hrefValue !== '#';
  }

  onMounted(() => {
    setTimeout(() => {
      nextTick(() => generateLinks());
    }, 0);
  });

  return {};
}
