import { UAParser } from "ua-parser-js";
import type { PageViewPayload } from "@/services/view";
import type { PageType } from "@/types";

export function getUserAgent(pageType?: PageType): PageViewPayload {
  const ua = new UAParser().getResult();
  return {
    pageType: pageType as PageType || '',
    platform: ua.device.type || 'desktop',
    browser: ua.browser.name || '',
    browserVersion: ua.browser.version || '',
    osName: ua.os.name || '',
    osVersion: ua.os.version || '',
    deviceModel: ua.device.model || '',
    deviceVendor: ua.device.vendor || ''
  };
}
