import { Payment } from '@/services/payment';
import { PaymentProvider } from '@/shared/elements/common/payment/types';

export const usePaymentStore = defineStore('payment', () => {
  const domain = globalThis?.location?.origin;
  Payment.BASE_PATH = `${domain}/api/payment`;
  const googlePayEnvironment = useRuntimeConfig().public.NUXT_GOOGLE_PAY_ENVIRONMENT;
  const isGooglePayProd = googlePayEnvironment === 'PRODUCTION';

  const providerConfigs = ref({
    applePay:{},
    googlePay:{
      isReadyToPayRequest: {},
      tokenization: {},
      transactionId: null,
      transactionInfo: {},
      paymentDataRequest: {}
    }
  });
  const googlePayClient = ref(null);
  const isGoogleReadyToPay = ref(false);
  const isApplePaySdkLoaded = ref(false);

  async function setProviderConfig(providerKey: PaymentProvider, paymentInitResponse: Record<string, any>, countryCode: string) {
    const { currency, price, transactionId, merchantId, appName = '' } = paymentInitResponse?.result || {};
    switch(providerKey) {
      case PaymentProvider.APPLE_PAY: {
        const applePayParams = paymentInitResponse?.result?.configs?.applePay?.parameters || {};
        providerConfigs.value[PaymentProvider.APPLE_PAY] = {
          requestPayload: {
            ...applePayParams,
            currencyCode: currency,
            total: {
              label: appName,
              amount: price
            }
          },
          transactionId
        }
        break;
      }
      case PaymentProvider.GOOGLE_PAY: {
        const googlePayConfig = paymentInitResponse?.result?.configs?.googlePay || {};
        const googlePayTokenizationSpecification = googlePayConfig?.tokenizationSpecification || {};
        const baseRequest = {
          apiVersion: 2,
          apiVersionMinor: 0
        };
        const baseCardPaymentMethod = {
          type: googlePayConfig?.type,
          parameters: googlePayConfig?.parameters
        };
        const cardPaymentMethod = {
          ...baseCardPaymentMethod,
          tokenizationSpecification: googlePayTokenizationSpecification
        };
        const transactionInfo = {
          countryCode,
          currencyCode: currency,
          totalPriceStatus: 'FINAL',
          totalPrice: price
        }
        providerConfigs.value[PaymentProvider.GOOGLE_PAY] = {
          isReadyToPayRequest: {
            ...baseRequest,
            allowedPaymentMethods: [baseCardPaymentMethod]
          },
          transactionInfo,
          paymentDataRequest: {
            ...baseRequest,
            transactionInfo,
            merchantInfo: {
              merchantName: appName,
              ...(isGooglePayProd && { merchantId: merchantId || '' })
            },
            allowedPaymentMethods: [cardPaymentMethod]
          },
          tokenization: googlePayTokenizationSpecification,
          transactionId
        }
        break;
      }
      default:
        break;
    }
  }

  function prefetchGooglePaymentData() {
    (googlePayClient.value as any)?.prefetchPaymentData(providerConfigs.value[PaymentProvider.GOOGLE_PAY]?.paymentDataRequest)
  }

  function onApplePaySdkLoaded() {
    isApplePaySdkLoaded.value = true;
  }

  function onGooglePayLoaded() {
    const paymentClient = getGooglePaymentsClient();
    const isReadyToPayRequest = JSON.parse(JSON.stringify(providerConfigs?.value?.googlePay?.isReadyToPayRequest || {}));
    googlePayClient.value = paymentClient;
    (paymentClient as any)?.isReadyToPay(isReadyToPayRequest)
      .then((response:any) => {
        isGoogleReadyToPay.value = !!response?.result;
        prefetchGooglePaymentData();
      })
      .catch((err:any) => console.error(err));
  }

  // Return an active PaymentsClient or initialize
  function getGooglePaymentsClient() {
    if (googlePayClient.value === null) {
      googlePayClient.value = new google.payments.api.PaymentsClient({ environment: googlePayEnvironment });
    }
    return googlePayClient.value;
  }

  return {
    providerConfigs,
    isGoogleReadyToPay,
    googlePayClient,
    isApplePaySdkLoaded,
    setProviderConfig,
    onGooglePayLoaded,
    onApplePaySdkLoaded,
    getGooglePaymentsClient
  }
});
