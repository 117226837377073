import { $wait } from "@/shared/utils/wait"
import { api } from './api';

export class Packages {
  static BASE_PATH = 'packages';
  static PACKAGE_BASE_PATH = 'package';

  static async getPackages(options?: { onlySelectedPackage?: boolean }, headers?: Record<string, any>) {
    try {
      $wait.start('getPackages');
      const { onlySelectedPackage = false } = options || {};
      const url = `${Packages.BASE_PATH}${onlySelectedPackage ? '?onlySelectedPackage=true' : ''}`
      const response = await api.get(url, headers);
      return response.data;
    } finally {
      $wait.end('getPackages');
    }
  }

  static async selectPackage(payload: Record<'packageId', number>, headers?: Record<string, any>) {
    const response = await api.post(`${Packages.PACKAGE_BASE_PATH}/select`, payload, headers);
    return response.data;
  }
}

export default Packages;
